
import { Vue, Options } from 'vue-class-component';
import debounce from 'lodash-es/debounce';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import {
  BaseTextInput,
  ButtonLink,
  SpecialityButton,
  DataTable,
  BasePopover,
  BasePagination,
  PopoverButton,
  PopoverLink,
  BaseButton,
  BaseModal,
  BaseAvatar,
  RolesSelector,
  BaseTooltip,
  BaseSnackbar,
  ActionModal
} from '@/lib/components';
import { DomainUserService, OrganisationRoleService, OrganisationUserService, UserService } from '@/services/api';
import { Domain, Organisation, PaginatedResponse, Role, User } from '@/models';
import { isValidEmail } from '@/helpers/strings.helper';
import { IModalAction } from '@/lib';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    RolesSelector,
    BaseTextInput,
    BaseModal,
    BaseButton,
    PopoverLink,
    PopoverButton,
    BasePagination,
    BasePopover,
    DataTable,
    ButtonLink,
    SpecialityButton,
    BaseAvatar,
    BaseTooltip,
    BaseSnackbar,
    ActionModal
  },
  props: {
    organisation: {
      type: Object,
      required: true
    }
  }
})
export default class UsersPage extends Vue {
  organisation!: Organisation | Domain;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  loading = true;
  columns = [
    { name: 'given_name', label: 'User', sortable: true },
    { name: 'roles', label: '', align: 'right' }
  ];

  // Invite User
  showInviteUser = false;
  emailSearch = '';
  inviteError = '';
  inviteUsers: User[] = [];
  inviteUserId: string | null = null;
  userInvitedSnackbar = false;

  users: User[] = [];
  roles: Role[] = [];
  selectedRoles: number[] = [];
  perPage = 0;
  total = 0;
  search = '';
  unWatchRoute = null;
  userService = new UserService();
  request: CancelTokenSource | null = null;

  removeUserId: string | null = null;

  get viewUserPageRoute(): string {
    return this.$route.name === 'domain-admin-organisation-users' ?
      'domain-admin-organisation-users-edit' :
      'settings-users-edit';
  }

  get rolesService(): OrganisationRoleService {
    return new OrganisationRoleService(this.organisationId);
  }

  get orgUserService(): OrganisationUserService {
    return new OrganisationUserService(this.organisationId);
  }

  get domainUserService(): DomainUserService {
    return new DomainUserService(this.organisationId);
  }

  get rows(): any[] {
    return this.users
      ? this.users.map((user) => ({
        ...user,
        activated: !!user.activated_at,
        roles:
          this.showRoles && user.roleAssignments
            ? user.roleAssignments
              .map((roleAssignment) => {
                const role = this.roles.find((r) => r.id === roleAssignment.role_id);
                return role ? role.name : '';
              })
              .filter((v) => v.length)
            : []
      }))
      : [];
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'family_name';
  }

  get isDomainOrSubdomain(): boolean {
    return this.organisation?.type === 'domain' || this.organisation?.type === 'subdomain';
  }

  get currentUserService() {
    return this.isDomainOrSubdomain ? this.domainUserService : this.orgUserService;
  }

  get organisationId(): string {
    return this.organisation?.id || '';
  }

  get removeUserModalActions(): Array<IModalAction> {
    return [
      {
        label: this.$t('platform.user.remove-from-org.modal.remove-user-label') as string,
        color: 'primary',
        onClick: () => this.removeUser(this.removeUserId as string)
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.removeUserId = null)
      }
    ];
  }

  get showRoles() {
    return !!(!this.isDomainOrSubdomain && (this.userPermissionList?.includes('role:read') || this.$route.meta?.isAdmin));
  }

  get userPermissionList(): string[] {
    return this.isDomainOrSubdomain ? [] : this.sessionStore.permissions;
  }

  get validateEmailDebounced() {
    return debounce(() => this.validateEmail(), 500);
  }

  async created() {
    this.loading = true;
    this.progressStore.startProgress();
    this.search = String(this.$route.query.search || '');
    await this.fetchInitData();
    await this.watchAndRefetch();
    this.loading = false;

    this.$watch('emailSearch', () => {
      this.validateEmailDebounced();
    });
    this.$watch('organisationId', async () => {
      if (this.organisationId) {
        if (Object.keys(this.$route.query).length) {
          await this.$router.replace({ path: this.$route.path });
        }
        this.search = String(this.$route.query.search || '');
        await this.fetchInitData();
      }
    });
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
    if (this.unWatchRoute) {
      this.unWatchRoute();
    }
  }

  async fetchInitData() {
    await this.fetchUsers();
    await this.fetchRoles();
  }

  watchAndRefetch() {
    this.unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        this.loading = true;
        await this.fetchInitData();
        this.loading = false;
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  validateEmail() {
    if (isValidEmail(this.emailSearch)) {
      this.inviteError = '';
    } else {
      this.inviteError = this.$t('platform.user.invite-user-invalid-email') as string;
    }
  }

  openInviteUser() {
    this.selectedRoles = [];
    this.showInviteUser = true;
  }

  async fetchRoles() {
    if (this.showRoles) {
      try {
        this.roles = (await this.rolesService.index()).data;
      } catch (e) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.role.fetch-error')
        });
      }
    }
  }

  async fetchUsers() {
    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        page: this.page,
        sort: this.sort,
        include: this.isDomainOrSubdomain ? null : 'roleAssignments',
        ...(this.search ? { search: this.search } : {})
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.currentUserService.index(requestConfig)) as PaginatedResponse<User[]>;
      this.request = null;
      this.users = response.data;
      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.user.fetch-error')
        });
      }
    }
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { search: this.search } : {})
      }
    });
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async removeUser(userId: string) {
    this.loading = true;

    try {
      await this.currentUserService.delete(userId);
      this.notificationStore.snackbar = {
        label: this.$t('platform.user.remove-from-org.success')
      };
      this.removeUserId = null;
      await this.fetchUsers();
      if (!this.isDomainOrSubdomain && userId === this.sessionStore.currentUser?.id) {
        await this.sessionStore.fetchCurrentUser();
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.error'),
          label: this.$t('platform.user.remove-from-org.error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async requestPasswordReset(userId: string) {
    try {
      await this.currentUserService.resetPassword(userId);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.user.reset-password-success')
      });
    } catch (err) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('platform.user.reset-password-error')
      });
    }
  }

  async resendInvite(userId: string) {
    try {
      await this.currentUserService.resendInvite(userId);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.user.invite-resent')
      });
    } catch (err) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('platform.user.invite-resend-error')
      });
    }
  }

  closeInviteUserModal() {
    this.emailSearch = '';
    this.inviteUserId = null;
    this.inviteError = '';
    this.showInviteUser = false;
  }

  dismissUserInvitedSnackbar() {
    this.userInvitedSnackbar = false;
  }

  async fetchInviteUsers() {
    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        ...(this.emailSearch ? { 'filter[email]': this.emailSearch } : {}),
        'fields[users]': 'id,email,given_name,family_name'
      },
      cancelToken: this.request.token
    };

    try {
      this.inviteUsers = await this.userService.searchUserByEmail(requestConfig);
      this.inviteError = '';
    } catch (err) {
      if (err.response.status === 422) {
        this.inviteError = this.$t('platform.error.email-error') as string;
      } else if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.user.fetch-error')
        });
      }
    } finally {
      this.request = null;
    }
  }

  async inviteUser() {
    this.inviteUsers = [];
    this.inviteUserId = null; // Reset the user id
    if (this.emailSearch === '') {
      this.inviteError = this.$t('platform.user.invite-user-invalid-email') as string;
    } else if (isValidEmail(this.emailSearch)) {
      this.loading = true;
      await this.fetchInviteUsers();

      const user = this.inviteUsers.find((u) => u.email === this.emailSearch.toLowerCase());
      if (user) {
        this.inviteUserId = user.id;
        await this.inviteUserToCurrentOU(this.inviteUserId);
      } else {
        // no user found in search - route to new user page after invite
        if (this.$route.name === 'settings-users') {
          await this.$router.push({
            name: 'settings-users-new',
            params: {
              organisationId: this.organisationId
            },
            query: {
              email: this.emailSearch,
              roles: this.selectedRoles.join(',')
            }
          });
        }
        if (this.$route.name === 'domain-admin-organisation-users') {
          await this.$router.push({
            name: 'domain-admin-organisation-users-new',
            params: {
              organisationId: this.organisationId
            },
            query: {
              email: this.emailSearch,
              roles: this.selectedRoles.join(',')
            }
          });
        }
      }
      this.loading = false;
    }
  }

  async inviteUserToCurrentOU(userId: string) {
    try {
      await this.currentUserService.addUser(userId);
      if (
        (this.$route.meta?.isAdmin || this.userPermissionList.includes('user-role:assign')) &&
        !this.isDomainOrSubdomain
      ) {
        await this.orgUserService.setRoles(userId, this.selectedRoles);
      }
      await this.fetchUsers();
      if (!this.isDomainOrSubdomain && userId === this.sessionStore.currentUser?.id) {
        await this.sessionStore.fetchCurrentUser();
      }
      this.inviteError = '';
      this.showInviteUser = false;
      this.emailSearch = '';
      this.userInvitedSnackbar = true;
    } catch (error) {
      if (
        error.response?.data &&
        ['organisation.user-conflict', 'domain.user-conflict'].includes(error.response.data.translation_key)
      ) {
        this.inviteError = this.$t('platform.user.invite-error-user-exists', {
          organisationName: this.organisation?.name || ''
        }) as string;
      } else {
        // generic error
        this.inviteError = this.$t('platform.user.invite-error', [this.emailSearch]) as string;
      }
    } finally {
      this.loading = false;
    }
  }
}
